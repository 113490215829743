/**
 * This interface and class define results that can be stored. The fields are self explanatory except for mode.
 * mode: 1 - manual
 *       2 - automated via user code
 *
 * created_at is a timestamp of seconds since epoch.
 */

export function convertMilesPerSecondToMetersPerSecond(milesPerSecond:number) {
  return (milesPerSecond * 1609.34);
}

export function convertMilesPerHourToMetersPerSecond(mph:number) {
  return (mph / 2.237);
}

export function convertMilesToMeters(miles:number) {
  return convertFeetToMeters(5280 * miles);
}

export function convertFeetToMeters(feet:number) {
  return (feet * 0.3048);
}

export interface Results {
  lander_name: string,
  mode: number,
  velocity: number,
  velocity_mps: number,
  remaining_fuel: number,
  crater_depth?: number,
  crater_depth_meters?: number,
  created_at?: number
}

export class LanderResults implements Results {
  lander_name: string;
  mode: number;
  velocity: number;
  velocity_mps: number;
  remaining_fuel: number;
  crater_depth?: number;
  crater_depth_meters?: number;
  created_at? : number;
  constructor(landerName: string, mode: number, velocity: number, remainingFuel: number, craterDepth?: number, createdAt?: number) {
    this.lander_name = landerName;
    this.mode = mode;
    this.velocity = velocity;
    this.velocity_mps = convertMilesPerSecondToMetersPerSecond(this.velocity);
    this.remaining_fuel = remainingFuel;
    this.crater_depth = craterDepth;
    if (this.crater_depth) {
      this.crater_depth_meters = convertFeetToMeters(this.crater_depth);
    }
    this.created_at = createdAt;
  }
}